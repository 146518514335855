import { useTranslation } from 'react-i18next'
import LanguageModal from '#app/features/localisation/language-modal'
import { cn } from '#app/utils/misc'
import PlentyBody from './typography/plenty-body'

export function FooterReturn() {
	let { t } = useTranslation('footer')

	return (
		<footer className="mx-auto w-full bg-white text-black-90 md:px-[var(--plenty-padding)]">
			<div className=" flex w-full flex-col justify-between  gap-4 py-4 md:flex-row ">
				<div className="flex flex-wrap justify-between px-4 md:gap-6 md:px-0">
					<div className="flex gap-2 ">
						<div className={cn('mr-auto max-w-sm md:visible')}>
							<LanguageModal />
						</div>
					</div>
					<div>
						<PlentyBody size="sm">Plenty&</PlentyBody>
					</div>
					<div>
						<PlentyBody
							size={'sm'}
						>{`© ${new Date().getFullYear()} All Rights Reserved`}</PlentyBody>
					</div>
				</div>
				<div className="flex flex-wrap justify-between px-4 md:gap-6 md:px-0">
					<div>
						<PlentyBody size={'sm'}>{t('terms_&_conditions')}</PlentyBody>
					</div>
					<div>|</div>
					<div>
						<PlentyBody size={'sm'}>{t('privacy_policy')}</PlentyBody>
					</div>
					<div>|</div>
					<div>
						<PlentyBody size={'sm'}>Cookies</PlentyBody>
					</div>
				</div>
			</div>
		</footer>
	)
}
